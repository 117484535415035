import React from "react";
import { Route, Switch } from "react-router-dom";

import Landing from "components/principal/landing/Landing";
import AllCoursesStats from "components/principal/all-courses/AllCoursesStats";
import Report from "components/principal/course-report/Report";
import StudentWiseReport from "components/principal/student-report/StudentWiseReport";
import StudentDetails from "components/principal/student-report/StudentDetails";

export default function Container() {
  return (
    <Switch>
      <Route exact path="/principal/report/course/all" component={AllCoursesStats} />
      <Route path="/principal/report/course/:courseId" component={Report} />
      <Route exact path="/principal/report/student" component={StudentWiseReport} />
      <Route path="/principal/report/student/:studentId" component={StudentDetails} />
      <Route path="/principal" component={Landing} />
    </Switch>
  );
}
