import { useState, useEffect, createContext, useContext } from "react";
import { getRequest } from "utils/requests";

import { isLoggedIn } from "utils/helper";

const CourseContext = createContext();

export function CourseContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState(null);
  const [dropdownCourses, setDropdownCourses] = useState([{ label: "All Courses", value: "all" }]);

  function getCourses() {
    getRequest("/course/courses?status=1")
      .then((resp) => {
        let update = [...dropdownCourses];
        const formattedArr = resp.data.map((item) => {
          update.push({
            label: item.name,
            value: item._id,
          });
          return {
            label: item.name,
            value: item._id,
          };
        });
        setDropdownCourses(update);
        setCourses(formattedArr);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (isLoggedIn) getCourses();
  }, []);

  const value = { courses, dropdownCourses, isLoading };

  return <CourseContext.Provider value={value}>{children}</CourseContext.Provider>;
}

export function useCourses() {
  return useContext(CourseContext);
}
