import React, { useState } from "react";
import style from "./Login.module.scss";
import { useHistory } from "react-router-dom";

import logo from "assets/logo.png";

import { postRequest } from "utils/requests";
import { useUser } from "context/UserContextProvider";
import Preloader from "components/shared/Preloader";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [formdata, setFormdata] = useState({ username: "", password: "" });
  const [isError, setIsError] = useState(false);

  const history = useHistory();
  const User = useUser();

  function handleFormChange(e) {
    let update = { ...formdata };
    update[e.target.name] = e.target.value;
    setFormdata(update);
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    let payload = { ...formdata };

    setIsError(false);
    postRequest("/auth/login", payload)
      .then((resp) => {
        localStorage.setItem("authToken", resp.data.token);
        if (resp.data.role === "principal") {
          User.getUserData();
          history.push("/");
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) setIsError(true);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className={style.wrapper}>
      <div className={style.card}>
        <div className={style.logo}>
          <img src={logo} alt="" />
        </div>
        {isLoading ? (
          <Preloader />
        ) : (
          <form onChange={handleFormChange} onSubmit={handleFormSubmit}>
            <label>Username</label>
            <input value={formdata.username} required type="text" name="username" />
            <label>Password</label>
            <input required type="password" name="password" />
            {isError && <div className={style.error}>Wrong Credentials, please try again</div>}
            <button>Login</button>
          </form>
        )}
      </div>
    </div>
  );
}
