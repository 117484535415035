import React, { useEffect, useState } from "react";
import style from "./Landing.module.scss";

import Header from "components/shared/Header";
import StatsCard from "components/shared/StatsCard";
// import IndiaMap from "components/shared/IndiaMap";
// import SearchForm from "components/shared/SearchForm";
// import SearchResultTable from "components/shared/SearchResultTable";

import {
  FaMapMarkerAlt,
  FaSchool,
  FaUserCircle,
  FaBook,
  FaCheckCircle,
  FaUser,
} from "react-icons/all";

import clsx from "clsx";
import GenerateReportForm from "components/shared/GenerateReportForm";

import { useUser } from "context/UserContextProvider";
import Preloader from "components/shared/Preloader";
// import OverallStatistics from "./OverallStatistics";
import { getRequest } from "utils/requests";

const STATS = [
  {
    name: "Total Students",
    num: 6,
    icon: <FaUserCircle />,
    theme: "brown",
  },
  {
    name: "Total Jayaho Courses",
    num: 20,
    icon: <FaBook />,
    theme: "green",
  },
  {
    name: "Allocated Licenses",
    num: 6400,
    icon: <FaUserCircle />,
    theme: "oilve",
  },
  // {
  //   name: "Total Schools",
  //   num: 32,
  //   icon: <FaSchool />,
  //   theme: "purple",
  // },
  // {
  //   name: "Total Students",
  //   num: 6400,
  //   icon: <FaUserCircle />,
  //   theme: "brown",
  // },
  // {
  //   name: "Total Courses",
  //   num: 2,
  //   icon: <FaBook />,
  //   theme: "green",
  // },
  // {
  //   name: "Total Licenses",
  //   num: 12800,
  //   icon: <FaCheckCircle />,
  //   theme: "blue",
  // },
];

export default function Landing() {
  const { user, isLoading } = useUser();

  const [studentCount, setStudentCount] = useState(0);
  const [courseCount, setCourseCount] = useState(0);
  const [licenseCount, setLicenseCount] = useState(0);

  function getStats() {
    getRequest("/reporting/school/student-count")
      .then((resp) => {
        setStudentCount(resp.data.studentCount);
      })
      .catch((err) => console.log(err));
    getRequest("/reporting/school/license-count")
      .then((resp) => {
        setLicenseCount(resp.data.licenseCount);
      })
      .catch((err) => console.log(err));
    getRequest("/reporting/school/course-count")
      .then((resp) => {
        setCourseCount(resp.data.courseCount);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getStats();
  }, []);

  return isLoading ? (
    <Preloader />
  ) : (
    <div className={style.wrapper}>
      <Header
        heading={`Hi ${user && user.name}, Welcome🌻`}
        school={user && user.institutionDetails && user.institutionDetails.name}
        location={`${user && user.cityDetails && user.cityDetails.stateFullName}, ${
          user && user.cityDetails && user.cityDetails.name
        }`}
      />
      {/* <div className={style.mapContainer}>
        <IndiaMap />
      </div> */}

      <div className={style.maxWidthContainer}>
        <div className={style.statsContainer}>
          <p className={style.heading}>Overall Statistics 📊</p>
          <div className={style.cards}>
            <StatsCard
              statsName="Total Students"
              statsNum={studentCount}
              icon={<FaUserCircle />}
              theme="brown"
            />
            <StatsCard
              statsName="Total Jayaho Courses"
              statsNum={courseCount}
              icon={<FaBook />}
              theme="green"
            />
            <StatsCard
              statsName="Allocated Licenses"
              statsNum={licenseCount}
              icon={<FaUserCircle />}
              theme="olive"
            />
          </div>
        </div>
      </div>
      <div className={style.searchForm}>
        <div className={style.maxWidthContainer}>
          <GenerateReportForm />
        </div>
      </div>
    </div>
  );
}
