import React from "react";
import style from "./Button.module.scss";
import clsx from "clsx";

export default function Button(props) {
  const {
    text,
    prefixIcon,
    type,
    onClick,
    backgroundColor,
    color,
    width,
    height,
    fontSize,
    borderRadius,
    className,
  } = props;

  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(className, style.button)}
      style={{ backgroundColor, color, width, height, fontSize, borderRadius }}
    >
      {prefixIcon}
      {text}
    </button>
  );
}
