import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import "./App.css";

export default function App() {
  return (
    <div>
      {/* Cache Busting Logic Here  */}
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </div>
  );
}
