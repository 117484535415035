import React from "react";
import style from "./Header.module.scss";

export default function Header({ heading, subHeading, school, location }) {
  return (
    <div className={style.wrapper}>
      <div className={style.maxWidthContainer}>
        <p className={style.heading}>{heading}</p>
        <p className={style.subHeading}>{subHeading}</p>
        <p className={style.school}>{school}</p>
        <p className={style.location}>{location}</p>
      </div>
    </div>
  );
}
