import React, { useState, useEffect } from "react";
import style from "./GenerateReportForm.module.scss";

import Select from "react-select";
import { colors } from "components/shared/colors";
import Button from "components/shared/Button";

import { getRequest } from "utils/requests";
import { useCourses } from "context/CourseContextProvider";

import { useHistory } from "react-router-dom";

const options = [
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const REPORT_TYPE = [
  { label: "Student Wise", value: "student" },
  { label: "Course Wise", value: "course" },
];

export default function GenerateReportForm() {
  const [reportType, setReportType] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const [nullCourseErr, setNullCourseErr] = useState(false);

  const history = useHistory();
  const { dropdownCourses } = useCourses();

  function handleFormSubmit(e) {
    e.preventDefault();
    if (reportType === "student") {
      history.push("/principal/report/student");
    } else if (reportType === "course") {
      if (selectedCourse === "all") {
        history.push("/principal/report/course/all");
      }
      if (selectedCourse) {
        history.push(`/principal/report/course/${selectedCourse}`);
      } else {
        setNullCourseErr("Please select course to proceed");
      }
    } else {
      setNullCourseErr("Please select the report type");
    }
  }

  function handleReportTypeChange(payload) {
    setNullCourseErr(null);
    setReportType(payload.value);
  }

  function handleCourseChange(payload) {
    setNullCourseErr(null);
    setSelectedCourse(payload.value);
  }

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: colors.ORANGE,
        primary25: colors.ORANGE,
        neutral0: "#f1f1f1",
      },
    };
  }

  return (
    <div className={style.wrapper}>
      <div className={style.maxWidthContainer}>
        <div className={style.form}>
          <div className={style.header}>
            <p>Generate Report 📝</p>
            <p>Select the following filters to proceed:</p>
          </div>

          <form onSubmit={handleFormSubmit}>
            <div className={style.row}>
              <div className={style.block}>
                <label>Report Type</label>
                <Select
                  className={style.selectField}
                  options={REPORT_TYPE}
                  onChange={handleReportTypeChange}
                  theme={selectTheme}
                />
              </div>
              {reportType !== "student" && (
                <div className={style.block}>
                  <label>Course</label>
                  <Select
                    className={style.selectField}
                    options={dropdownCourses}
                    onChange={handleCourseChange}
                    theme={selectTheme}
                  />
                </div>
              )}
            </div>
            {/* <div className={style.row}>
                  <div className={style.block}>
                    <SelectField
                      theme={selectTheme}
                      className={style.selectField}
                      options={options}
                      name="state"
                      onFieldChange={handleChange}
                      onFieldBlur={handleBlur}
                      label="State"
                    />
                  </div>
                  <div className={style.block}>
                    <SelectField
                      theme={selectTheme}
                      className={style.selectField}
                      options={options}
                      name="city"
                      onFieldChange={handleChange}
                      onFieldBlur={handleBlur}
                      label="City"
                    />
                  </div>
                </div> */}
            {/* <div className={style.row}>
              <div className={style.block}>
                <SelectField
                  theme={selectTheme}
                  className={style.selectField}
                  options={options}
                  name="school"
                  label="School"
                />
              </div>
              <div className={style.block}>
                <SelectField
                  theme={selectTheme}
                  className={style.selectField}
                  options={options}
                  name="grade"
                  label="Grade"
                />
              </div>
            </div> */}
            {nullCourseErr && <p style={{ color: "red" }}>{nullCourseErr}</p>}
            <div className={style.controller}>
              <Button
                type="submit"
                text="Generate"
                backgroundColor={colors.ORANGE}
                color={colors.WHITE}
                width="150px"
              />
              {/* <Button
                type="button"
                text="Reset"
                backgroundColor={colors.WHITE}
                color={colors.ORANGE}
                width="100px"
              /> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
