import React, { useState, useEffect } from "react";
import style from "./Report.module.scss";

import { useHistory, useParams } from "react-router-dom";
import Button from "components/shared/Button";
import { FiArrowLeft, FiDownload } from "react-icons/fi";
import { FaUserCircle, FaUserFriends } from "react-icons/fa";
import { colors } from "components/shared/colors";
import clsx from "clsx";
import { PieChart } from "react-minimal-pie-chart";
import { multiGetRequest } from "utils/requests";
import Preloader from "components/shared/Preloader";

import { useCourses } from "context/CourseContextProvider";
import { useUser } from "context/UserContextProvider";

export default function Report() {
  const [isLoading, setIsLoading] = useState(true);

  const [courseName, setCourseName] = useState("");
  const [reportData, setReportData] = useState({});

  const { courses } = useCourses();
  const { user } = useUser();
  const params = useParams();
  const history = useHistory();

  function goBack() {
    history.goBack();
  }

  function getCourseData() {
    let endpoints = [
      `/reporting/students-count/${params.courseId}`,
      `/reporting/courses/completion/${params.courseId}`,
      `/reporting/courses/game-results/${params.courseId}`,
    ];

    multiGetRequest(endpoints)
      .then((resp) => {
        const enrollledCount = resp[0].data.count;
        const completion = parseFloat(resp[1].data.avgCompletion.toFixed(2));

        let accuracy = resp[2].data.avgAccuracy;
        let speed = resp[2].data.avgSpeed;

        if (accuracy == null || accuracy === undefined) {
          accuracy = 0;
        } else {
          accuracy = parseFloat(resp[2].data.avgAccuracy.toFixed(2));
        }

        if (speed === null || speed === undefined) speed = 0;
        else speed = parseFloat(resp[2].data.avgSpeed.toFixed(2));

        setReportData({
          enrollledCount,
          completion,
          accuracy,
          speed,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getCourseData();
  }, []);

  useEffect(() => {
    if (!courses) return;

    if (courses.length) {
      const courseName = courses.find((i) => i.value == params.courseId);
      setCourseName(courseName.label);
    }
  }, [courses]);

  return isLoading && !reportData ? (
    <Preloader />
  ) : (
    <div className={style.wrapper}>
      <div className={style.nav}>
        <div className={style.maxWidthContainer}>
          <Button
            className={style.backButton}
            text="Go Back"
            prefixIcon={<FiArrowLeft />}
            backgroundColor={colors.SKY_BLUE}
            onClick={goBack}
          />
          {/* <Button
            className={style.downloadReport}
            text="Downlaod Report"
            prefixIcon={<FiDownload />}
            width="160px"
            backgroundColor={colors.LIGHT_BLUE_1}
          /> */}
        </div>
      </div>
      <div className={style.header}>
        <div className={style.maxWidthContainer}>
          <div className={style.info}>
            <p>Course Name</p>
            <p>{courseName}</p>
          </div>
          <div className={style.info}>
            <p>City</p>
            <p>
              {user && user.cityDetails && user.cityDetails.name},{" "}
              {user && user.cityDetails && user.cityDetails.stateFullName}{" "}
            </p>
          </div>
          <div className={style.info}>
            <p>School</p>
            <p>{user && user.institutionDetails && user.institutionDetails.name}</p>
          </div>
          {/* <div className={style.info}>
            <p>Grade</p>
            <p>Class 8</p>
          </div> */}
        </div>
      </div>
      <div className={style.maxWidthContainer}>
        <div className={style.reportCards}>
          <div className={clsx(style.card, style.purple)}>
            <div className={style.report}>
              <p className={style.statNumber}>{reportData.enrollledCount}</p>
              <p className={style.statName}>Students Enrolled</p>
              <div className={style.icon}>
                <FaUserFriends />
              </div>
            </div>
          </div>
          {/* <div className={clsx(style.card, style.brown)}>
            <div className={style.report}>
              <p className={style.statNumber}>162</p>
              <p className={style.statName}>Students In Progress</p>
              <div className={style.icon}>
                <FaUserCircle />
              </div>
            </div>
          </div>
          <div className={clsx(style.card, style.green)}>
            <div className={style.report}>
              <p className={style.statNumber}>5</p>
              <p className={style.statName}>Students Completed</p>
              <div className={style.icon}>
                <FaUserFriends />
              </div>
            </div>
          </div>
          <div className={clsx(style.card, style.pink)}>
            <div className={style.report}>
              <p className={style.statNumber}>356</p>
              <p className={style.statName}>Students Not Started</p>
              <div className={style.icon}>
                <FaUserFriends />
              </div>
            </div>
          </div> */}
          <div className={clsx(style.card, style.blue)}>
            <div className={style.report}>
              <p className={style.statNumber}>
                {reportData.completion ? reportData.completion.toFixed(2) : 0}%
              </p>
              <p className={style.statName}>Percentage Completion</p>
              <div className={style.icon}>
                <FaUserFriends />
              </div>
            </div>
          </div>
          {courseName !== "Decision Making" && (
            <div className={clsx(style.card, style.chart)}>
              <p className={style.statName}>Average Accuracy</p>
              <div className={style.donut}>
                <PieChart
                  data={[
                    { value: reportData.accuracy ? reportData.accuracy : 0, color: "#263238" },
                  ]}
                  totalValue={100}
                  background="#cfd8dc"
                  lineWidth={15}
                  startAngle={270}
                  label={({ dataEntry }) => `${dataEntry.value}%`}
                  labelStyle={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fill: "#263238",
                  }}
                  labelPosition={0}
                />
              </div>
            </div>
          )}

          <div className={clsx(style.card, style.chart)}>
            <p className={style.statName}>Average Speed</p>
            <div className={style.donut}>
              <PieChart
                data={[
                  { value: reportData.speed ? reportData.speed.toFixed(2) : 0, color: "#263238" },
                ]}
                totalValue={10}
                background="#cfd8dc"
                lineWidth={15}
                startAngle={270}
                label={({ dataEntry }) => `${dataEntry.value}s`}
                labelStyle={{
                  fontSize: "18px",
                  fontWeight: "600",
                  fill: "#263238",
                }}
                labelPosition={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
