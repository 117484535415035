import React from "react";
import styles from "./Preloader.module.scss";

import GridLoader from "react-spinners/GridLoader";

export default function Preloader() {
  return (
    <div className={styles.backdrop}>
      <GridLoader color="#6776FF" loading={true} size={20} />
    </div>
  );
}
