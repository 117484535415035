import axios from "axios";
const BASE_URL = "https://api.jayaho.io";

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/";
      return;
    }

    return Promise.reject(error);
  }
);

export function getRequest(endpoint) {
  return axios.get(`${BASE_URL}${endpoint}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });
}

export async function multiGetRequest(paths) {
  let arr = [];

  paths.forEach((item) => {
    arr.push(
      axios.get(`${BASE_URL}${item}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
    );
  });
  return axios.all(arr);
}

export function postRequest(endpoint, payload) {
  return axios.post(`${BASE_URL}${endpoint}`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });
}
