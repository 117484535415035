import React from "react";
import style from "./PerformanceSummary.module.scss";

import clsx from "clsx";
import { ChevronDown } from "react-feather";
import { PolarArea } from "react-chartjs-2";
import Select from "react-select";

import { usePerformanceSummary } from "./usePerformanceSummary";

export default function PerformanceSummary() {
  const {
    statsPerCourse,
    activeAccordions,
    toggleAccordion,
    graphStatsOptions,
    graphSelectedStat,
    handleChangeSelectedStat,
    graphData,
    legendData,
  } = usePerformanceSummary();

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: "120px",
      padding: "0px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer !important",
      padding: "10px",
    }),
    control: () => ({
      width: 120,
      display: "flex",
      cursor: "pointer !important",
      background: "#eeeeee",
      borderRadius: "5px",
    }),
    valueContainer: (p) => ({
      ...p,
      width: "200px",
      flex: "unset",
    }),
    indicatorSeparator: (p) => ({ ...p, display: "none !important" }),
    singleValue: (provided, state) => {
      return { ...provided };
    },
  };

  return statsPerCourse && statsPerCourse.length ? (
    <div className={style.wrapper}>
      <h1>Course Performance Summary</h1>

      <div className={style.grid}>
        <div className={style.column}>
          {statsPerCourse &&
            statsPerCourse.map((item, index) => (
              <div
                key={index}
                className={clsx(style.accordion, !activeAccordions[item.id] && style.accordionOpen)}
              >
                <div
                  onClick={toggleAccordion.bind(this, item.id)}
                  className={clsx(style.header, item.theme)}
                >
                  <div>
                    <p>Course</p>
                    <p style={{ color: item.theme }}>{item.course}</p>
                  </div>
                  <div>
                    <p>Accuracy</p>
                    <p>
                      {item.modules
                        .reduce(
                          (accum, curr) =>
                            accum / item.modules.length + curr.avgAccuracy / item.modules.length,
                          0
                        )
                        .toFixed(2)}
                      %
                    </p>
                  </div>
                  <div>
                    <p>Avg Speed</p>
                    <p>
                      {item.modules
                        .reduce(
                          (accum, curr) =>
                            accum / item.modules.length + curr.avgSpeed / item.modules.length,
                          0
                        )
                        .toFixed(2)}
                      secs
                    </p>
                  </div>
                  <div>
                    <ChevronDown />
                  </div>
                </div>
                <div className={style.report}>
                  <div className={style.titles}>
                    <p>Modules</p>
                    <p>Accuracy</p>
                    <p>Speed</p>
                  </div>
                  {item.modules.map((module, index) => (
                    <div key={index} className={style.dataRow}>
                      <p>{module.courseName}</p>
                      <p>{module.avgAccuracy ? module.avgAccuracy.toFixed(2) : 0}%</p>
                      <p>{module.avgSpeed ? module.avgSpeed.toFixed(2) : 0} secs</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        {graphData && graphStatsOptions && legendData && (
          <div className={style.graphWrapper}>
            <div className={style.selectWrapper}>
              <Select
                styles={customStyles}
                options={graphStatsOptions}
                value={graphSelectedStat}
                onChange={(opt) => handleChangeSelectedStat(opt)}
              />
            </div>
            <div className={style.legendWrapper}>
              {legendData.map((item) => (
                <div className={style.legendItem}>
                  <div className={style.box} style={{ background: item.color }}></div>
                  <div className={style.course}>{item.course}</div>
                </div>
              ))}
            </div>
            <div className={style.chartWrapper}>
              {console.log(graphData)}
              <PolarArea
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const unit = graphSelectedStat.value === "avgSpeed" ? "s" : "%";
                          return `${context.label} : ${
                            context.dataset.data[context.dataIndex]
                          }${unit}`;
                        },
                      },
                    },
                  },
                  maintainAspectRatio: false,
                }}
                data={graphData}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
}
