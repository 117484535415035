import React, { useState, useEffect } from "react";
import style from "./StudentDetails.module.scss";
import { useHistory, useParams } from "react-router-dom";

import { FiX, FiDownload, FiBookOpen, FiMail, FiPhone, FiEdit2 } from "react-icons/fi";
import { getRequest } from "utils/requests";
import Preloader from "components/shared/Preloader";

import profile from "assets/profile.png";
import PerformanceSummary from "./PerformanceSummary";

import { useUser } from "context/UserContextProvider";

export default function StudentDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);

  const history = useHistory();
  const params = useParams();
  const { user } = useUser();

  function closeStudentHistory() {
    history.push("/report/student");
  }

  useEffect(() => {
    getRequest(`/reporting/student/${params.studentId}`)
      .then((resp) => {
        console.log(resp);
        setDetails(resp.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return isLoading && !details ? (
    <Preloader />
  ) : (
    <div className={style.wrapper}>
      <div className={style.nav}>
        <div className={style.maxWidthContainer}>
          <p>Student Details</p>
          <button onClick={closeStudentHistory}>
            <FiX />
          </button>
        </div>
      </div>

      <div className={style.cardsContainer}>
        <div className={style.maxWidthContainer}>
          <div className={style.profileCard}>
            <div className={style.header}>
              <div className={style.image}>
                <img src={profile} alt="" />
              </div>
              <div className={style.text}>
                <p>{details.user.profile.name}</p>
                <p>
                  Grade {details.user.profile.currClass},
                  {user && user.institutionDetails && user.institutionDetails.name}
                </p>
              </div>
            </div>
            {/* <div className={style.contact}>
              <p>
                <FiMail /> atimabh@jayaho.io
              </p>
              <p>
                <FiPhone /> 8802216901
              </p>
            </div> */}
            {/* <div className={style.controller}>
              <button>
                <FiEdit2 /> Edit
              </button>
            </div> */}
          </div>
          <div className={style.card}>
            <div className={style.row}>
              <p>Assigned Courses</p>
              {/* <button>
                <FiDownload /> Download Report
              </button> */}
            </div>
            <div className={style.modules}>
              <div className={style.section}>
                {details.courseCompletion
                  .slice(0, details.courseCompletion.length / 2)
                  .map((item, index) => (
                    <div key={index} className={style.module}>
                      <FiBookOpen /> {item.courseName} - {item.percentCompletion.toFixed(2)} %
                    </div>
                  ))}
              </div>
              <div className={style.section}>
                {details.courseCompletion
                  .slice(details.courseCompletion.length / 2, details.courseCompletion.length)
                  .map((item, index) => (
                    <div key={index} className={style.module}>
                      <FiBookOpen /> {item.courseName} - {item.percentCompletion.toFixed(2)} %
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.performanceWrapper}>
        <PerformanceSummary />
      </div>
    </div>
  );
}
