import React, { useState } from "react";
import style from "./SearchResultTable.module.scss";
import clsx from "clsx";

import { FiSearch, FiDownload, FiInfo } from "react-icons/fi";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import { useHistory } from "react-router-dom";

export default function SearchResultTable({ data }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const history = useHistory();

  function toggleFullScreen() {
    setIsFullScreen((prev) => !prev);
  }

  function openStudentDetails(studentId) {
    history.push(`/principal/report/student/${studentId}`);
  }

  return (
    <div className={clsx(style.wrapper, isFullScreen && style.fullscreen)}>
      <div className={style.header}>
        <p className={style.heading}></p>
        <div className={style.controllers}>
          {/* <form>
            <div className={style.searchBox}>
              <div className={style.icon}>
                <FiSearch />
              </div>
              <input type="text" placeholder="Search by UID or Student Name" />
            </div>
          </form>
          <button>
            <FiDownload />
          </button> */}
          <button onClick={toggleFullScreen}>
            {!isFullScreen ? <BsFullscreen /> : <BsFullscreenExit />}
          </button>
        </div>
      </div>
      <div className={style.table}>
        <table cellSpacing="0">
          <thead>
            <tr>
              <td>Student Name</td>
              <td>Student Username</td>
              <td>% Completion</td>
              <td>Details</td>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.userId}>
                <td>{item.name}</td>
                <td>{item.userName}</td>
                <td>{item.sumCompletionPercent.toFixed(2)} %</td>
                <td>
                  <button onClick={openStudentDetails.bind(this, item.userId)}>
                    <FiInfo />
                    Info
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
