import React, { useState, useEffect } from "react";
import style from "./AllCoursesStats.module.scss";
import { useHistory, useParams } from "react-router-dom";

import Button from "components/shared/Button";
import { FiArrowLeft } from "react-icons/fi";
import { colors } from "components/shared/colors";
import { getRequest, multiGetRequest } from "utils/requests";
import Preloader from "components/shared/Preloader";

export default function AllCoursesStats() {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  const params = useParams();
  const history = useHistory();

  function goBack() {
    history.push("/");
  }

  function getCourseData() {
    setIsLoading(true);
    getRequest("/course/courses?status=1")
      .then((resp) => {
        let temp = [];
        resp.data.forEach((item) => {
          let endpoints = [
            `/reporting/students-count/${item._id}`,
            `/reporting/courses/completion/${item._id}`,
            `/reporting/courses/game-results/${item._id}`,
          ];

          multiGetRequest(endpoints)
            .then((resp) => {
              const enrollledCount = resp[0].data.count;
              const completion = resp[1].data.avgCompletion;
              const accuracy = resp[2].data.avgAccuracy;
              const speed = resp[2].data.avgSpeed;

              const data = {
                name: item.name,
                bundleName: item.bundleName,
                enrollledCount,
                completion,
                accuracy,
                speed,
              };
              temp.push(data);
              setCourses((prev) => [...prev, data]);
            })
            .catch((err) => console.log(err));
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getCourseData();
  }, []);

  return isLoading || !courses || !courses.length ? (
    <Preloader />
  ) : (
    <div className={style.wrapper}>
      <div className={style.nav}>
        <div className={style.maxWidthContainer}>
          <Button
            className={style.backButton}
            text="All courses statistics"
            prefixIcon={<FiArrowLeft />}
            backgroundColor={colors.SKY_BLUE}
            onClick={goBack}
          />
        </div>
      </div>

      <div className={style.maxWidthContainer}>
        {courses && courses.length
          ? courses.map((item, index) => (
              <div key={index} className={style.section}>
                <div className={style.title}>
                  <p>Course ({item.bundleName})</p>
                  <p>{item.name}</p>
                </div>
                <div className={style.stats}>
                  <div>
                    <p>{item.enrollledCount}</p>
                    <p>Students Enrolled</p>
                  </div>
                  <div>
                    <p>{item.completion ? item.completion.toFixed(2) : 0}%</p>
                    <p>Percentage Completion</p>
                  </div>
                  {item.name !== "Decision Making" && (
                    <div>
                      <p>{item.accuracy ? item.accuracy.toFixed(2) : 0}%</p>
                      <p>Average Accuracy</p>
                    </div>
                  )}

                  <div>
                    <p>{item.speed ? item.speed.toFixed(2) : 0} secs</p>
                    <p>Average Speed</p>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}
