import React, { useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import Navbar from "components/layout/Navbar";

import { CourseContextProvider } from "context/CourseContextProvider";
import { UserContextProvider } from "context/UserContextProvider";
import Login from "components/auth/Login";
import Container from "components/principal/Container";

function isLoggedIn() {
  if ("authToken" in localStorage) {
    return true;
  } else {
    return false;
  }
}

export default function AppRouter() {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/login" && <Navbar />}

      <CourseContextProvider>
        <UserContextProvider>
          <Switch>
            <Route path="/login" component={Login} />

            <Route
              path="/principal"
              render={() => (isLoggedIn() ? <Container /> : <Redirect to="/login" />)}
            />

            <Route path="/" render={() => <Redirect to="/principal" />} />
          </Switch>
        </UserContextProvider>
      </CourseContextProvider>
    </>
  );
}
