import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getRequest } from "utils/requests";

const _GraphBackgroundColor = [
  "rgba(244,108,48,0.7)",
  "rgba(255, 159, 64, 0.7)",
  "rgba(234,67,53,0.7)",
  "rgba(153, 102, 255, 0.7)",
  "rgba(75, 192, 192, 0.7)",
  "rgba(106,44,112,0.7)",
];

const _GraphStatOptions = [
  {
    value: "avgAccuracy",
    label: "Accuracy",
  },
  {
    value: "avgSpeed",
    label: "Speed",
  },
];

const mapCourseToColor = {
  Language: "rgba(244,108,48,0.7)",
  Numbers: "rgba(255, 159, 64, 0.7)",
  "Problem Solving": "rgba(234,67,53,0.7)",
  "Activity Sequencing": "rgba(153, 102, 255, 0.7)",
  "Decision Making": "rgba(75, 192, 192, 0.7)",
  "Flowchart Building": "rgba(106,44,112,0.7)",
};

const _LegendData = [
  {
    color: "rgba(244,108,48,0.7) ",
    course: "Language",
  },
  {
    color: "rgba(255, 159, 64, 0.7)",
    course: "Numbers",
  },
  {
    color: "rgba(234,67,53,0.7) ",
    course: "Problem Solving",
  },
  {
    color: "rgba(153, 102, 255, 0.7) ",
    course: "Activity Sequencing",
  },
  {
    color: "rgba(75, 192, 192, 0.7)",
    course: "Decision Making",
  },
  {
    color: "rgba(106,44,112,0.7)",
    course: "Flowchart Building",
  },
];

export function usePerformanceSummary() {
  const [activeAccordions, setActiveAccordions] = useState({ 0: true, 1: true });
  const [graphSelectedStat, setGraphSelectedStat] = useState(_GraphStatOptions[0]);

  const [legendsData, setLegendsData] = useState([]);
  const [graphData, setGraphData] = useState(null);

  const [statsPerCourse, setStatsPerCourse] = useState(null);

  const params = useParams();

  function getGraphData(stat, arr) {
    console.log(arr);
    let data = [];
    let _Labels = [];
    let graphBackgroundColor = [];
    let legends = [];

    arr.forEach((course) => {
      course.modules.forEach((mod) => _Labels.push(mod.courseName));
    });
    arr.forEach((course) => {
      course.modules.forEach((mod) => {
        const value = parseFloat(mod[stat].toFixed(2));
        data.push(value);
      });
    });
    console.log(data);
    arr.forEach((course) => {
      course.modules.forEach((mod) => {
        graphBackgroundColor.push(mapCourseToColor[mod.courseName]);
        legends.push({
          color: mapCourseToColor[mod.courseName],
          course: mod.courseName,
        });
      });
    });

    setLegendsData(legends);

    setGraphData({
      labels: _Labels,
      datasets: [
        {
          label: "Percent",
          data: data,
          backgroundColor: graphBackgroundColor,
          borderWidth: 1,
        },
      ],
    });
  }

  function toggleGraphStat(stat) {
    switch (stat) {
      case "avgSpeed":
        getGraphData("avgSpeed", statsPerCourse);
        break;
      case "avgAccuracy":
        getGraphData("avgAccuracy", statsPerCourse);
        break;
      default:
        return {};
    }
  }

  function toggleAccordion(accordionId) {
    setActiveAccordions((prev) => {
      return { ...prev, [accordionId]: !activeAccordions[accordionId] };
    });
  }

  function handleChangeSelectedStat(option) {
    setGraphSelectedStat(option);
  }

  function getPerformanceData() {
    getRequest(`/reporting/student/performance/summary/${params.studentId}`)
      .then((resp) => {
        console.log(resp);
        let temp = [];
        resp.data.forEach((item) => {
          const checkIndex = temp.findIndex(({ course }) => course == item.bundleName);
          if (checkIndex == -1) {
            temp.push({
              id: item.bundleId,
              course: item.bundleName,
              theme: item.bundleColorTheme,
              modules: resp.data.filter((i) => i.bundleName === item.bundleName),
            });
          }
        });

        setStatsPerCourse(temp);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (graphSelectedStat && statsPerCourse && statsPerCourse.length)
      toggleGraphStat(graphSelectedStat.value);
  }, [graphSelectedStat]);

  useEffect(() => {
    getPerformanceData();
  }, []);

  useEffect(() => {
    if (statsPerCourse && statsPerCourse.length) {
      getGraphData("avgAccuracy", statsPerCourse);
    }
  }, [statsPerCourse]);

  return {
    statsPerCourse: statsPerCourse,
    activeAccordions,
    graphSelectedStat,
    toggleAccordion,
    handleChangeSelectedStat,
    graphData,
    graphStatsOptions: _GraphStatOptions,
    legendData: legendsData,
  };
}
