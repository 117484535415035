import React from "react";
import style from "./Navbar.module.scss";
import logo from "assets/logo.png";
export default function Navbar() {
  function logout() {
    localStorage.removeItem("authToken");
    window.location.href = "/login";
  }

  return (
    <div className={style.wrapper}>
      <div className={style.maxWidthContainer}>
        <div className={style.logo}>
          <img src={logo} alt="" />
        </div>
        <p className={style.heading}>School Principal</p>
        <div className={style.controllers}>
          <div className={style.user}>
            <button onClick={logout}>Logout</button>
          </div>
        </div>
      </div>
    </div>
  );
}
