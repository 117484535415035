import { useState, useEffect, createContext, useContext } from "react";
import { getRequest } from "utils/requests";

import { isLoggedIn } from "utils/helper";

const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  function getUserData() {
    getRequest("/user/profile/principal")
      .then((resp) => {
        console.log(resp);
        setUser(resp.data[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (isLoggedIn()) getUserData();
  }, []);

  const value = { user, isLoading, getUserData };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUser() {
  return useContext(UserContext);
}
