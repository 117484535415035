import React, { useState, useEffect } from "react";
import style from "./StudentWiseReport.module.scss";

import SearchResultTable from "components/principal/student-report/SearchResultTable";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { getRequest } from "utils/requests";
import Preloader from "components/shared/Preloader";

export default function StudentWiseReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [students, setStudents] = useState(null);
  const history = useHistory();

  function handleGoBack() {
    history.push("/");
  }

  function getStudentReports() {
    getRequest("/reporting/students/overall")
      .then((resp) => {
        console.log(resp);
        setStudents(resp.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getStudentReports();
  }, []);

  return isLoading && !students ? (
    <Preloader />
  ) : (
    <div className={style.wrapper}>
      <div className={style.nav}>
        <div className={style.maxWidthContainer}>
          <button onClick={handleGoBack}>
            <FiArrowLeft /> Go Back
          </button>
        </div>
      </div>
      <div className={style.maxWidthContainer}>
        <div className={style.header}>
          <p>Generated Student Wise Report</p>
        </div>
        <div className={style.table}>
          <SearchResultTable data={students} />
        </div>
      </div>
    </div>
  );
}
