import React from "react";
import style from "./StatsCard.module.scss";
import clsx from "clsx";

export default function StatsCard({ theme, icon, statsNum, statsName }) {
  function getCardTheme(theme) {
    switch (theme) {
      case "black":
        return style.black;
      case "pink":
        return style.pink;
      case "purple":
        return style.purple;
      case "brown":
        return style.brown;
      case "green":
        return style.green;
      case "blue":
        return style.blue;
      case "olive":
        return style.olive;
      default:
        return style.black;
    }
  }

  return (
    <div className={clsx(style.wrapper, getCardTheme(theme))}>
      <div className={style.icon}>{icon}</div>
      <p>{statsNum}</p>
      <p>{statsName}</p>
    </div>
  );
}
